import React, { useState } from 'react';
import MLM from '../contract/MLM';
import MLMDeposit from '../contract/MLMDeposit'
import { ToastContainer, toast } from 'react-toastify';
import { ethers } from 'ethers';

export default function Admin() {
  const [address, setAddress] = useState('');
  const [blackListAddress, setBlackListAddress] = useState('');
  const [withUsdtAmount, setWithUsdtAmount] = useState();
  const [depositWithAmount, setdepositWithAmount] = useState();
  
  const [loader, setLoader] = useState(false);
  const handleSubmit = async () => {
    try {
      setLoader(true)
      let _allAdd = address.split(',');
      console.log('🚀 ~ handleSubmit ~ _allAdd', _allAdd);
      let _add = await MLM.addWhiteList(_allAdd);
      let _wait = await _add.wait();
      if (_wait) {
        toast.success('Transaction successful!');
        setLoader(false)

      }
    } catch (error) {
      console.log('🚀 ~ handleSubmit ~ error', error);
      toast.error('Something went wrong!');
      setLoader(false)

    }
  };
  const handleOwnerWithdrawUsdt = async () => {
    try {
      let usdtAmount = ethers.utils.parseEther(withUsdtAmount);
      let _add = await MLM.getTokenFromContract(usdtAmount);
      let _wait = await _add.wait();
      if (_wait) {
        toast.success('Transaction successful!');

      }
    } catch (error) {
      console.log('🚀 ~ handleSubmit ~ error', error);
      toast.error('Something went wrong!');
      setLoader(false)

    }
  };

  const handleWithDespoitToken = async () => {
    try {
      let usdtAmount = ethers.utils.parseEther(depositWithAmount);
      let _add = await MLMDeposit.getTokenFromContract(usdtAmount);
      let _wait = await _add.wait();
      if (_wait) {
        toast.success('Transaction successful!');

      }
    } catch (error) {
      console.log('🚀 ~ handleSubmit ~ error', error);
      toast.error('Something went wrong!');
      setLoader(false)

    }
  };

  const handleBlackList = async () => {
    try {
      setLoader(true)
      let _allAdd = blackListAddress.split(',');
      console.log('🚀 ~ handleSubmit ~ _allAdd', _allAdd);
      let _add = await MLM.removeWhiteList(_allAdd);
      let _wait = await _add.wait();
      if (_wait) {
        toast.success('Transaction successful!');
        setLoader(false)

      }
    } catch (error) {
      console.log('🚀 ~ handleSubmit ~ error', error);
      toast.error('Something went wrong!');
      setLoader(false)

    }
  };

  return (
    <>
      <ToastContainer />
      <div className='container py-4'>
        <div className='row d-flex justify-content-center'>
          <div className='col-6'>
            <h4 className='text-dark'>Enter WhiteList Address</h4>
            <div class='form-group'>
              <textarea
                class='form-control'
                id='exampleFormControlTextarea3'
                rows='5'
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              ></textarea>
            </div>
            <div className='row d-flex justify-content-center'>
              <div className='col d-flex justify-content-center'>
                {loader ? (
                  <div class='spinner-border text-success' role='status'>
                    <span class='visually-hidden'>Loading...</span>
                  </div>
                ) : (
                  <button
                    onClick={handleSubmit}
                    className='btn btn-primary my-2'
                  >
                    submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container py-4'>
        <div className='row d-flex justify-content-center'>
          <div className='col-6'>
            <h4 className='text-dark'>Enter BlackList Address</h4>
            <div class='form-group'>
              <textarea
                class='form-control'
                id='exampleFormControlTextarea3'
                rows='5'
                value={blackListAddress}
                onChange={(e) => setBlackListAddress(e.target.value)}
              ></textarea>
            </div>
            <div className='row d-flex justify-content-center'>
              <div className='col d-flex justify-content-center'>
                {loader ? (
                  <div class='spinner-border text-success' role='status'>
                    <span class='visually-hidden'>Loading...</span>
                  </div>
                ) : (
                  <button
                    onClick={handleBlackList}
                    className='btn btn-primary my-2'
                  >
                    submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <div container py-4>
        <div className='row d-flex justify-content-center'>
        <div className='col-4'>
            <h4>Withdraw DOT From Withdraw Contract</h4>
            <input
              style={{
                backgroundColor: "#D9D9D9",
                borderRadius: "5px",
                color: "#2f323b ",
                fontWeight: "500",
                fontSize: "18px",
                
              }}
              className="form-control"
              type="text"
              placeholder="Enter DOT Amount"
              aria-label="default input example"
              value={withUsdtAmount}
              onChange={(e) => {
                setWithUsdtAmount(e.target.value);
              
              }}
            />
            <br></br>
            <div>
            <button className='col-4 d-flex justify-content-center btn btn-primary' onClick={handleOwnerWithdrawUsdt}>Withdraw DOT</button>
            </div>



            </div>

        </div>

      </div>
      <br></br>
      <div container py-4>
        <div className='row d-flex justify-content-center'>
        <div className='col-4'>
            <h4>Withdraw DOT From Deposit Contract</h4>
            <input
              style={{
                backgroundColor: "#D9D9D9",
                borderRadius: "5px",
                color: "#2f323b ",
                fontWeight: "500",
                fontSize: "18px",
                
              }}
              className="form-control"
              type="text"
              placeholder="Enter DOT Amount"
              aria-label="default input example"
              value={depositWithAmount}
              onChange={(e) => {
                setdepositWithAmount(e.target.value);
              
              }}
            />
            <br></br>
            <div>
            <button className='col-4 d-flex justify-content-center btn btn-primary' onClick={handleWithDespoitToken}>Withdraw DOT</button>
            </div>



            </div>

        </div>

      </div>
    </>
  );
}
