import './App.css';
import Admin from './components/Home/Admin';
import Home from './components/Home/Home';
import { BrowserRouter, Routes, Route, Navigate,
  useLocation,
  useNavigate, } from 'react-router-dom';
  import bgImg from './asserts/green_leave154.jpg'

function App() {
  return (
    <>
      <div className='bgImg' >
        <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/admin' element={<Admin/>} />
        </Routes>
      </BrowserRouter>
    </div>

    </>
  );
}

export default App;
