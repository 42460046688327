import React, {useState, useEffect} from 'react';
import MLM from '../contract/MLM';
import MLMDeposit from '../contract/MLMDeposit';
import {ToastContainer, toast} from 'react-toastify';
import bigInt from 'big-integer';
import axios from 'axios';
import {Link} from 'react-router-dom';

export default function Home() {
  const [isOwner, setIsOwner] = useState(false);
  const [userAddress, setUserAddress] = useState('');
  const [show, setShow] = useState(false);

  const [isValid, setIsValid] = useState(false);
  const [isAlreadyRegister, setIsAlreadyRegister] = useState(false);
 
  const [buttonStatus, setButtonStatus] = useState('');
  const [regbuttonStatus, setRegButtonStatus] = useState('');
   const [refId, setRefId] = useState('');
   const [nodetype, setNodetype]= useState('');
 
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    handleUrl();
    return () => {};
  }, []);

  const handleUrl = async () => {
    try {
      let url = window.location.href;
      console.log("url", url);
     let nodetype = url.split("=")[1];
    console.log("nodetype",nodetype);
      setRefId(nodetype);
  
      if (nodetype == undefined) {
        return toast.error('Refid not found.');
      }

   
    } catch (error) {
      console.log('🚀 ~ handleUrl ~ error', error);
    }
  };

  useEffect(() => {
    if (userAddress) {
    handleCheckAlreadyRegister();
    }
    return () => {};
  }, [userAddress]);

  const handleWalletConnect = async () => {
    if (window.ethereum) {
      window.ethereum
        .request({method: 'eth_requestAccounts'})
        .then(handleAccountsChanged)
        .catch((err) => {
          if (err.code === 4001) {
            // EIP-1193 userRejectedRequest error
            // If this happens, the user rejected the connection request.
          } else {
            console.error(err);
          }
        });
      return true;
    } else {
      return false;
    }
  };
  function handleAccountsChanged(accounts) {
    let currentAccount;

    if (window.ethereum) {
      if (window.ethereum.networkVersion !== '56') {
       return toast.error('Please connect to Binance Mainnet');
      }
    }

    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      // console.log("Please connect to MetaMask.");
    } else if (accounts[0] !== currentAccount) {
      currentAccount = accounts[0];
      setUserAddress(currentAccount);

      // Do any other work!
    }
  }
  useEffect(() => {
    getAdmin();
   
    return () => {};
  }, [userAddress]);

  const getAdmin = async () => {
    try {
      if (userAddress) {


        let owner = await MLM.owner();

        let despositContarctOwner = await MLMDeposit.owner();
        console.log('owner', owner);
        console.log('ownerDeposit', despositContarctOwner);

        if (userAddress.toLowerCase() == owner.toLowerCase()) {
          setIsOwner(true);
        }
        if (userAddress.toLowerCase() == despositContarctOwner.toLowerCase()) {
          setIsOwner(true);
        }
      }
    } catch (error) {
      console.log('🚀 ~ getAdmin ~ error', error);
    }
  };

  const handleUserRegister = async () => {
    try {
      setButtonStatus('register');
        let _reg = await axios.post(
          `https://libertycoin.io/dashboard/api_root_main/register.php?refid=${refId}&address=${userAddress}`
        );

       
      console.log("_reg",_reg);

      setButtonStatus('');
      setRegButtonStatus('userLogged');
      handleCheckAlreadyRegister();
      toast.success('Registration successfull.');
  
    } catch (error) {
      setButtonStatus('');
      if (error.message === 'Network Error') {
        console.log(error);
      }
    }
  };

   const handleCheckAlreadyRegister = async () => {
    try {
        let url = `https://libertycoin.io/dashboard/api_root_main/register1.php?address=${userAddress}`;
        let _logi = await axios.get(url);

        let stridata = _logi?.data[1];
        console.log("check register user", stridata);
        if (stridata === 'Status:200') {
          setIsAlreadyRegister(true);
         console.log("if true");
             
         }
        else{
           setIsAlreadyRegister(false)
            }
        
      }
      catch (error) {
      console.log(error);
    }
  };

  const handleUserLogin = async () => {
    try {
        console.log("checkiiiiiii", isAlreadyRegister);
        
      if (!userAddress) {
        return toast.error('Connect Wallet first!');
      }
        let url = `https://libertycoin.io/dashboard/api_root_main/login.php?address=${userAddress}`;
        let _logi = await axios.get(url);

        let stridata = _logi?.data;
        
        let ans = stridata[1];
        if (ans === 'Status:200') {
          if (window) {
            window?.location?.replace(
              `https://libertycoin.io/dashboard/index.php?address=${userAddress}`
            );
          }
          toast.success('Login success!');
          setButtonStatus('');
        } else {
          toast.error('Not registered!');
        }
      }
      catch (error) {
      console.log(error);
      let parse = JSON.stringify(error);
      let _par = JSON.parse(parse);
      console.log(_par);
      toast.error('Please register yourself!');
      setButtonStatus('');
    }
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <div className="">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="logo"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0">
        <div className="col-md-4 pe-3">
          {/* <a href="/">
            <img
              src="https://libertycoin.io/logo.png"
              alt="coin"
              loading="lazy"
              // className='myImg'
              width={80}
              height={80}
            />
          </a> */}
        </div>
        <div className="col-md-12  d-flex justify-content-center">
          {isOwner ? (
            <Link
              to={'/admin'}
              className="dashBoard wallet  btn btn-outline border-white text-white withdrawButton"
            >
              Admin
            </Link>
          ) : (
            ''
          )}
        </div>
      </div>

      {!isValid ? (
        <div className="container -fluid ">
          <div className="row mt-5">
            <div className="col-12">
              <div className="row d-flex justify-content-center">
                <div
                  className="col-lg-5 col-md-8  p-4 m-2 shadow-lg rounded-1 "
                  style={{
                    backgroundColor: '#fff'
                  }}
                >
                  <div className="col py-4 ">
                    <div className="row">
                      <div className="col-md-12 d-flex justify-content-center">
                        <img
                          src="https://libertycoin.io/logo.png"
                          alt="coin"
                          loading="lazy"
                          // className='myImg'
                          width={80}
                          height={80}
                        />
                      </div>
                    </div>
                    <div className="row py-3">
                      <div className="col-md-12 d-flex justify-content-center ">
                        {userAddress ? (
                          <button
                            className="dashBoard wallet  btn btn-outline border-white text-white withdrawButton"
                            disabled
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              width: '160px',
                              whiteSpace: 'nowrap',
                              color: 'black'
                            }}
                          >
                            {' '}
                            {userAddress}
                          </button>
                        ) : (
                          <button
                            className=" wallet2"
                            onClick={handleWalletConnect}
                          >
                            {' '}
                            Connect Wallet{' '}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="row py-3">
                      <div className="col-12 d-flex  justify-content-center">
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          disabled
                          value={userAddress}
                          placeholder=" Wallet Address"
                        />
                      </div>
                    </div>

                    <br />

                    <div className="row">
                      <div className="col d-flex justify-content-center">
                        {buttonStatus === 'login' ? (
                          <div
                            class="spinner-border text-success"
                            role="status"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <button
                            onClick={handleUserLogin}
                            className="btn btn-outline border-white text-white withdrawButton"
                          >
                            Login
                          </button>
                        )}
                      </div>
                       {isAlreadyRegister? ('') : (
                        <div className="col d-flex justify-content-center">
                        <>
                          {buttonStatus === 'register' ? (
                            <div
                              class="spinner-border text-success"
                              role="status"
                            >
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          ) : (
                            <button
                              className={`btn btn-outline border-white text-white  withdrawButton`}
                              onClick={handleUserRegister}
                            >
                              Register
                            </button>
                          )}{' '}
                        </>
                      </div>)}
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
